<template>
	<div>
		<!--承办单位列表-->
		<el-dialog title="查看承办单位" :visible.sync="showyy" width="70%">
			<div style="height: 60vh;overflow-y: auto;">
				<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader"
					:showbm="true" :czwidth="300" :showpages="false">
					<template slot="btns" slot-scope="{ scope }">
						<el-button @click="showtext(scope.row,1)" v-show="scope.row.dfhtype==3&&scope.row.state!=2"
							type="primary" size="mini" style="margin-left: 10px;">
							查看答复
						</el-button>
						<el-button @click="showtext(scope.row,2)" v-show="scope.row.dfhtype!=1&&scope.row.state!=2&&scope.row.blcl"
							type="primary" size="mini" style="margin-left: 10px;">
							查看承诺
						</el-button>					
						<el-button @click="lookyy(scope.row)" v-show="scope.row.cbdw_type==4" type="danger" size="mini"
							style="margin-bottom:10px;margin-left: 10px;">
							查看异议
						</el-button>
						
					</template>
				</MyTable>
			</div>
		</el-dialog>
		
		<!-- 查看答复函 -->
		<el-dialog custom-class="refuse-dialog" :title="showtitle" :visible.sync="showflag" >
			<div style="height:65vh;overflow-x: hidden;overflow-y: auto;" ref="print">
				<div class="myhtml" v-html="htmldiv">
					
				</div>
				<div v-show="showtitle=='查看承诺'" style="margin-top: 40px;color: #333;">
					我们承诺将于{{cntime}}前，完成以上工作内容
				</div>
			</div>
		
			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 10px;">
				<el-button type="success" size="small" @click.stop="print" style="margin-left: 10px;">打印</el-button>
				</el-button>
				<el-button @click="showflag=false" size="small">取消</el-button>
			</span>
		</el-dialog>
	</div>
	
</template>

<script>
	export default{
		data() {
			return{
				tableHeader: [
					{
						name: '承办单位',
						prop: 'organizational_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: '180'
					},
					{
						name: '状态',
						prop: 'cbdw_type',
						width: '120',
						type: 'tags_cbdw'
					}
				],
				tableData: [],
				showyy:false,
				showflag:false,
				showtitle:'',
				htmldiv:'',
				cntime:''
			}
		},
		methods:{
			print(){
				this.$printPage(this.$refs.print)
			},
			showtext(item,i){//i=1 查看答复，i=2 查看承诺
				this.htmldiv = i==1?item.dfh:item.blcl				
				this.showtitle = i==1?'查看答复':'查看承诺'
				console.log(this.showtitle)
				this.showflag = true
				this.cntime = item.blcltime
			},
			lookyy(item) {
				this.$alert('异议：'+item.yyly, '查看异议', {
					confirmButtonText: '确定',
					callback: action => {
						
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	
</style>